import styled from "styled-components";

export const FeaturedDiv = styled.div`
  margin: auto;

  h4 {
    text-align: center;
  }
  div {
    .textdiv{
       display: inline-block;
       margin: 10px 1.2rem;
       max-width: 250px;
       img{
         max-width: 250px;
       }
    }
  }
  
  .imgcont {
    justify-content: space-around;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

`;
