import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from "../../components/AppContext";

import NavBar from "../../components/NavBar/NavBar";
import { Footer } from "../../components/Footer/Footer";
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import { VideoSection } from '../../components/AdminAbout/Angular/VideoSection';
import { PagesShowcase } from '../../components/AdminAbout/Angular/PagesShowcase';
import { DocsPreview } from '../../components/AdminAbout/Angular/DocsPreview';
import { Hero } from '../../components/AdminAbout/Angular/Hero';
import { Feature } from '../../components/AdminAbout/Angular/Feature';
import { Showcase } from '../../components/AdminAbout/Angular/Showcase';
import { Comparison } from '../../components/AdminAbout/Angular/Comparison';
import { About as AboutSection } from '../../components/AdminAbout/Angular/About';
import '../../styles/scss/style.scss'

const AdminAbout = () => {

  return (
    <AppContext>
      <Helmet>
        <title>Angular Bootstrap Admin Template and Dashboard - Contrast</title>
        <meta name="title" content="Angular Bootstrap Admin Template and Dashboard - Contrast" />
        <meta
          name="description"
          content="Angular Bootstrap beautifully designed, responsive admin template and dashboards to power your admin websites and apps"
        />
        <meta property="og:title" content="Angular Bootstrap Admin Template and Dashboard - Contrast" />
        <meta
          property="og:description"
          content="Angular Bootstrap beautifully designed, responsive admin template and dashboards to power your admin websites and apps"
        />
        <meta property="twitter:title" content="Angular Bootstrap Admin Template and Dashboard - Contrast" />
        <meta
          property="twitter:description"
          content="Angular Bootstrap beautifully designed, responsive admin template and dashboards to power your admin websites and apps"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />
        <meta name="twitter:image" content="https://res.cloudinary.com/speedwares/image/upload/v1643031081/contrast-bootstrap_p456h2.jpg" />

      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user} />
              <Hero />
            </div>
            <VideoSection />
            <div className="container mt-5">
              <FeaturedIn />
            </div>
            <div className="container">
              <Feature />
              <AboutSection />
              <PagesShowcase />
            </div>
            <DocsPreview />
            <div className="container">
              <Showcase />
              <Comparison />
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default AdminAbout;
