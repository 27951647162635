import React from 'react';
import { FeaturedDiv } from './FeaturedIn.styles';
import hackerNews from '../images/ahacker.png';
import productHunt from '../images/aproducth.png';
import webdesignNews from '../images/awebdesign.png';
import webdesignDepot from '../images/awebdesignde.png';

export const FeaturedIn = () => {
  return (
    <FeaturedDiv className="container">
      <h4 className="center-block toptext text-3xl font-bold mt-10">Featured in</h4>

      <div className="imgcont flex justify-between">
        <p className="textdiv">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.producthunt.com/posts/loop-design-ui-kit"
          >
            <img src={productHunt} alt="product hunt logo" />
          </a>
        </p>

        <p className="textdiv">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.webdesignernews.com/comment/loop-design-ui-kit-a-free-ui-kit-for-busy-designers-and-startup-owners"
          >
            <img src={webdesignNews} alt="web designer news logo" />
          </a>
        </p>

        <p className="textdiv">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.webdesignerdepot.com/2021/01/popular-design-news-of-the-week-january-4-2021-january-10-2021/"
          >
            <img src={webdesignDepot} alt="webdesignerdepot logo" />
          </a>
        </p>

        <p className="textdiv">
          <a target="_blank" rel="noreferrer" href="https://news.ycombinator.com/item?id=25797646">
            <img src={hackerNews} alt="hacker news logo" />
          </a>
        </p>
      </div>
    </FeaturedDiv>
  );
};
