import React from 'react';
import { Link } from "gatsby";

export const About = () => {
  return (
    <div className="feature feature-3">
      <div className="feature__wrap">
        <div className="feature__left">
          <img
            src={require('../../../components/images/adminlastfree.png')}
            alt="#"
            className="feature__img"
          />
        </div>
        <div className="feature__right">
          <h2 className="feature__lead feature__lead--1 hero__lead">
            Give your admin website/apps a refreshing look
          </h2>
          <p className="feature__text">
            The Contrast bootstrap admin template was built from the ground up with the end users in mind. 
            We made sure to make the interface very intuitive. Get started using the Contrast admin dashboard today for your various projects.
         </p>
          <p className="feature__text">
            We also designed a Contrast Bootstrap Admin Pro Template to give you access to more amazing features and improvements. Have a look.
        </p>
          <div className="feature__buttons">
            <Link to="/app/downloads/" className="btn1 btn__inverse">
              <span className="btn__text">Download Admin Template</span>
            </Link>
            <Link to="/product/angular-contrast-pro/" className="btn1 btn-white">
              <span className="btn__text">Get Admin Pro</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
