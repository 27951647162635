import React, { Fragment } from 'react';
// import GitHubButton from 'react-github-btn';


export const Comparison = () => {
  return (
    <Fragment>
      <div  className="container" id="pricing">
        <div className="comparison">
          <div className="comparison__title">
            <h2 className="comparison__lead">Download Contrast</h2>
            <p className="comparison__sub-title text-center">
              You can support Contrast by leaving a star on our github to help others discover it and spread the word. 
              We will greatly appreciate it.
              <div className="mt-2 d-flex align-items-center justify-content-center">
                <a target="_blank" href="https://github.com/Devwares-Team/contrast-boostrap-ui-kit-multipurpose-template" rel="noreferrer"><button className="btn btn-secondary btn-sm rounded mb-2">View on Github</button></a>
                {/* <GitHubButton
                  href={"https://github.com/" + "Devwares-Team/contrast-boostrap-ui-kit-multipurpose-template"}
                  data-icon="octicon-star" data-size="large"
                  data-show-count="true"
                  aria-label={"Star " + "Contrast Bootstrap 5 UI Kit + Multipurpose Template" + " on GitHub"}
                >
                  Star
                </GitHubButton> */}
              </div>
            </p>
          </div>
          <div className="comparison__wrap" style={{width: "100%"}}>
            <div className="comparison__box">
              <div className="comparison__price">Contrast Bootstrap 5 UI Kit</div>
              <ul className="comparison__list2">
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="bad">10+ example Pages</li> 
                <li className="bad">Multilevel Sidebar</li>
                <li className="bad">Advanced Dashboards</li>
                <li className="bad">About Pages</li>
                <li className="bad">Chat Page</li>
                <li className="bad">Lifetime Premium Support</li>
                <li className="bad">Lifetime Unlimited Updates</li>
                <li className="bad">VIP Early access</li>
              </ul>
              <a href="/docs/contrast/angular/index/" className="btn1 btn__inverse btn__full">
                Install this
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">Contrast Bootstrap 5 UI Kit + Free Multipurpose Admin Template</div>        
              <ul className="comparison__list">
                <li className="good">Full documentation</li>
                <li className="good">2000+ Components</li>
                <li className="good">10+ example Pages</li>
                <li className="bad">Multilevel Sidebar</li>
                <li className="bad">Advanced Dashboards</li>
                <li className="bad">About Pages</li>
                <li className="bad">Chat Page</li>
                <li className="bad">Lifetime Premium Support</li>
                <li className="bad">Lifetime Unlimited Updates</li>
                <li className="bad">VIP Early access</li>
              </ul>
              <a href="/app/downloads/" className="btn1 btn__inverse btn__full">
                Get admin Template
              </a>
            </div>
            <div className="comparison__box">
              <div className="comparison__price">Contrast Bootstrap 5 PRO UI Kit + PRO Multipurpose Admin Template</div>
              <ul className="comparison__list">
                <li>Full documentation</li>
                <li>10000+ components</li>
                <li>27+ example pages</li>
                <li>Multilevel Sidebar</li>
                <li>Advanced Dashboards</li>
                <li>About Pages</li>
                <li>Chat Page</li>
                <li>Lifetime Premium Support</li>
                <li>Lifetime Unlimited Updates</li>
                <li>VIP Early access</li>
              </ul>
              <a href="/product/bootstrap-contrast-pro/" className="btn1 btn__inverse btn__full">
                Get Contrast Pro
              </a>
            </div>
            {/* <div className="comparison__box" style={{visibility: "hidden"}}>
            
            </div> */}
          </div>
        </div>
       
      </div>
    </Fragment>
  );
};
